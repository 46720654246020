import React, { useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import SEO from "@src/components/SEO";
import { logEvent } from "@src/utils/logClient";
import DistrictHero from "@src/components/partials/districtleader/DistrictHero";
import UsageReport from "@src/components/partials/districtleader/UsageReport";
import ZeroCost from "@src/components/partials/districtleader/ZeroCost";
import PrivacyByDefault from "@src/components/partials/districtleader/PrivacyByDefault";
import ComparisonTable from "@src/components/partials/districtleader/ComparisonTable";
import FaqSection from "@src/components/partials/districtleader/FAQs";
import DistrictTestimonials from "@src/components/partials/districtleader/Testimonials";
import CarouselSection from "@src/components/partials/districtleader/Carousel";
import DistrictsFeatures from "@src/components/partials/districtleader/Features";
import VideoSection from "@src/components/partials/districtleader/VideoSection";
import styled from "@emotion/styled";
import CalendlyModal from "@src/components/CalendlyModal";

export const CaptchaContainer = styled("div")`
  margin-top: 80px;
  height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  [class*="TextField"] {
    input {
      text-align: center;
    }
  }
`;

export const CalendarIframe = styled("iframe")`
  width: 100%;
  height: calc(100vh - 100px);
  margin: 24px 0;
  border: 0;
  border-radius: 24px;
`;

const numberNames = {
  0: "zero",
  1: "one",
  2: "two",
  3: "three",
  4: "four",
  5: "five",
  6: "six",
  7: "seven",
  8: "eight",
  9: "nine",
};

const faqSectionProps = {
  title: "Questions and answers.",
  faq: [
    {
      question: "Is ClassDojo for Districts really free for schools and districts?",
      answer:
        "Yes, ClassDojo for Districts is zero cost for districts—no limitations or paywalls.<br/><br/> ClassDojo is committed to remain 100% free for teachers, kids, schools and districts.",
    },
    {
      question: "What is ClassDojo for Districts?",
      answer:
        "ClassDojo for Districts is an all-in-one place for engagement and communication in the classes and schools throughout your district. <br /><br />Whether it’s a teacher encouraging positive behavior, a principal sharing important updates with families, or an entire school promoting events and communicating across 100+ languages—it’s all possible in the same place on ClassDojo. <br/><br/>With ClassDojo for Districts, all the schools that want ClassDojo can access it conveniently for free. Plus, you will receive district-grade security features, onboarding support, rostering, SSO, and professional development and support.",
    },
    {
      question: "What about people who already have ClassDojo accounts?",
      answer:
        "No problem at all. When your district adopts ClassDojo for Districts, anyone who is already using ClassDojo will benefit from rostering, SSO, training and support, but will also have access to everything they love about ClassDojo already via their existing account.",
    },
    {
      question: "Is ClassDojo for Districts secure and safe to use?",
      answer:
        "Student privacy and safety are our top priority. ClassDojo is fully compliant with COPPA and enables you to be fully compliant with FERPA. We’ve been certified as such by iKeepSafe, the most reputable independent third party accreditor. ClassDojo is also a proud signatory of the Student Privacy Pledge and an active member of the Student Data Privacy Consortium.",
    },
    {
      question: "How does ClassDojo protect student privacy?",
      answer:
        "• No advertisers or marketers. Anything shared on ClassDojo will never be shared with advertisers or marketers.<br /><br />• We use the latest security practices to protect schools at all times. We work with independent security researchers to put our systems under extreme, unbiased scrutiny.<br /><br />• Our privacy center explains all our practices clearly and simply. We will notify anyone with a ClassDojo account if we make any changes to our practices.",
    },
    {
      question: "I’m a teacher or school leader. How can I get ClassDojo for Districts?",
      answer:
        "We’d love to connect and share more resources to help your district understand the benefits of ClassDojo. Reach out to our team at <a href='mailto:districts@classdojo.com'>districts@classdojo.com</a>",
    },
  ],
};

const PageDistrictLeader = () => {
  const data = useStaticQuery(graphql`
    {
      avatar: file(name: { eq: "dl_avatar" }) {
        childImageSharp {
          gatsbyImageData(width: 72, quality: 90, placeholder: NONE, layout: FIXED)
        }
      }
    }
  `);

  useEffect(() => {
    logEvent({
      eventName: "web.external.districts.page_view",
      eventValue: window.location.href,
    });
  }, []);

  const [showCalendly, setShowCalendly] = useState(false);

  const openCalendly = () => {
    setShowCalendly(true);
    logEvent({
      eventName: "web.external.districts.open_calendly_captcha",
      eventValue: window.location.href,
    });
  };

  const closeCalendly = () => {
    setShowCalendly(false);
  };

  return (
    <>
      <SEO title="District Leaders" />
      <DistrictHero openCalendly={openCalendly} />
      <CarouselSection />
      <ComparisonTable openCalendly={openCalendly} />
      <DistrictsFeatures />
      <UsageReport />
      <PrivacyByDefault />
      <ZeroCost />
      <VideoSection openCalendly={openCalendly} />
      <DistrictTestimonials />
      <FaqSection {...faqSectionProps} />
      {showCalendly && (
        <CalendlyModal
          logEventContext="districts"
          calendlyLink="https://calendly.com/d/ck5s-dck-47n"
          closeModal={closeCalendly}
        />
      )}
    </>
  );
};

export default PageDistrictLeader;
